import axios from "axios";
import { apiUrl } from "../constants/api";

export const takeQuiz = async quizInfo => {
  const { data } = await axios.post(`${apiUrl}/quiz/take`, quizInfo, {
    headers: {
      "x-access-token": localStorage.getItem("token"),
    },
  });
  return data.data;
};

export const getUserQuiz = async () => {
  const { data } = await axios.get(`${apiUrl}/quiz/user-quiz`, {
    headers: {
      "x-access-token": localStorage.getItem("token"),
    },
  });
  return data.data;
};

export const setUserAnswers = async userAnswersInfo => {
  const { data } = await axios.post(`${apiUrl}/quiz/user-answers/set`, userAnswersInfo, {
    headers: {
      "x-access-token": localStorage.getItem("token"),
    },
  });
  return data.data;
};

export const resetUserQuiz = async () => {
  const res = await axios.delete(`${apiUrl}/quiz/reset-quiz`, {
    headers: {
      "x-access-token": localStorage.getItem("token"),
    },
  });
  console.log("Data", res)
  if(res.data.status === "success") {
    return res.data.data.status
  } else {
    return false
  }
};