import React, { useState, useEffect } from "react";
import moment  from 'moment';
import { Button, Modal, Row, Col } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";
import ScoreProgressBar from "../components/ScoreProgressBar";
import "../components/layout.scss";
import Layout from "../components/layout";
import Loading from '../components/Loading';
import { getFindings } from "../api/findings";
import { resetUserQuiz } from "../api/quiz";
import Auth from '../components/Auth';

const colorCodes = {
  expression: "#ff6665",
  comprehension: "#19baff",
  pronunciation: "#abcd51",
  readiness: "#ffc001",
};

const GetStartedButton = styled(Button)`
  position: fixed;
  top: 20px;
  right: 20px;
`;

const ExtraPaddingBottom = styled.div`
  padding-bottom: 30px;
`;

export const query = graphql`
  {
    findingsDescription: contentfulFindings {
      descriptionRetakeAssessment {
        descriptionRetakeAssessment
      }
      descriptionContinueToLessonPlan {
        descriptionContinueToLessonPlan
      }
    }
  }
`

const Findings = ({ data }) => {
  const [loading, setLoading] = useState(false);
  const [replayQuiz, setReplayQuiz] = useState(false);
  const [userDetails, setUserDetails] = useState({
    age: '',
    birthday: '',
    name: '',
    completedOn: '',
    remarks: {},
    overallPercentage: {
      totalPercentage: 0,
      expression: 0,
      comprehension: 0,
      readiness: 0,
      pronunciation: 0
    },
    score: {
      comprehension: { percentage: 0, total: 0, correct: 0 },
      expression: { percentage: 0, total: 0, correct: 0 },
      pronunciation: undefined,
      readiness: { percentage: 0, total: 0, correct: 0 }
    }
  });

  const {
    findingsDescription: {
      descriptionRetakeAssessment: {
        descriptionRetakeAssessment
      },
      descriptionContinueToLessonPlan: {
        descriptionContinueToLessonPlan
      }
    }
  } = data

  useEffect(async () => {
    setLoading(true);
    let data = await getFindings();
    if (data && data.childAge) {
      if ((Number(data.childAge) > 36 || Number(data.childAge) < 12)) {
        setUserDetails({
          ...userDetails,
          age: data.childAge,
          birthday: data.childBirthdate,
          name: data.childName,
          completedOn: data.lastCompletedOn,
          remarks: data.remarks
        });
      } else {
        setUserDetails({
          ...userDetails,
          age: data.childAge,
          birthday: data.childBirthdate,
          name: data.childName,
          completedOn: data.lastCompletedOn,
          overallPercentage: { ...data.overallPercentage },
          score: { ...data.score }
        });
      }
    }
    setLoading(false);
  }, []);

  const restartQuizPopup = () => {
    setReplayQuiz(!replayQuiz);
  }

  const resetQuiz = async () => {
    let data = await resetUserQuiz();
    if (data === true) {
      setReplayQuiz(false);
      window.location.href = "/quiz";
    } else {
      setReplayQuiz(false);
    }
  }

  const renderResults = () => {
    return (
      <>
        {/* ---- Expression ---- */}

        <div className=" findings-review-block elementor-section-wrap">
          <section
            className="elementor-section elementor-top-section elementor-element elementor-element-f6893e0 elementor-section-stretched elementor-section-full_width elementor-section-height-default elementor-section-height-default"
            data-id="f6893e0"
            data-element_type="section"
            data-settings='{"stretch_section":"section-stretched"}'
          >
            <div className="elementor-container elementor-column-gap-default ">
              <div
                className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-9f5f77a"
                data-id="9f5f77a"
                data-element_type="column"
              >
                <div className="elementor-widget-wrap elementor-element-populated">
                  <div
                    className="elementor-section elementor-inner-section elementor-element elementor-element-4522805 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                    data-id="4522805"
                    data-element_type="section"
                  >
                    <div className="elementor-container elementor-column-gap-default ">
                      <div
                        className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-367b21f"
                        data-id="367b21f"
                        data-element_type="column"
                      >
                        <div className="elementor-widget-wrap elementor-element-populated">
                          <div
                            className="elementor-element elementor-element-9265126 cms-element elementor-widget elementor-widget-cms_image_single"
                            data-id="9265126"
                            data-element_type="widget"
                            data-widget_type="cms_image_single.default"
                          >
                            <div className="elementor-widget-container animation-top">
                              <div className="cms-image-single cms-animation-bottom-to-top ">
                                <div className="cms-img-box">
                                  <StaticImage
                                    placeholder="none"
                                    width="80"
                                    src="../images/image-home-2.png"
                                    className="attachment-full size-full"
                                    alt=""
                                    loading="lazy"
                                  />{" "}
                                </div>
                              </div>{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-8869f2a"
                        data-id="8869f2a"
                        data-element_type="column"
                      >
                        <div className="elementor-widget-wrap elementor-element-populated">
                          <div
                            className="elementor-element elementor-element-8832270 elementor-widget elementor-widget-cms_heading"
                            data-id="8832270"
                            data-element_type="widget"
                            data-widget_type="cms_heading.default"
                          >
                            <div className="container-center">
                              <div className="cms-heading-wrapper cms-heading-layout1 wow pulse">
                                <h3 className="custom-heading">
                                  <span>
                                    Expression Score : {userDetails.score['expression']['percentage']} %
                                  </span>
                                </h3>
                                <ExtraPaddingBottom />
                                <ScoreProgressBar
                                  data={[
                                    {
                                      fillPercent: userDetails.score['expression']['percentage'],
                                      fillColor:
                                        colorCodes[
                                        "expression"
                                        ],
                                      consideringScoreOf:
                                        "expression",
                                    },
                                  ]}
                                />
                                <p>
                                  % skills equal to or more
                                  advanced than average peers.
                                </p>
                                <p className="text-left">
                                  Your child’s expression skills
                                  represent all the ways your
                                  child shows or tells you what
                                  they want and need. Expression
                                  skills include both verbal and
                                  nonverbal communication.
                                </p>
                                <ExtraPaddingBottom />
                                <a
                                  class="learn-more-btn"
                                  href="/reports?skill=expression"
                                >
                                  <span class="btn-text">
                                    Learn More{" "}
                                  </span>
                                </a>
                              </div>{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-6be929f"
                        data-id="6be929f"
                        data-element_type="column"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* comprehension */}

        <div className=" findings-review-block elementor-section-wrap">
          <section
            className="elementor-section elementor-top-section elementor-element elementor-element-f6893e0 elementor-section-stretched elementor-section-full_width elementor-section-height-default elementor-section-height-default"
            data-id="f6893e0"
            data-element_type="section"
            data-settings='{"stretch_section":"section-stretched"}'
          >
            <div className="elementor-container elementor-column-gap-default ">
              <div
                className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-9f5f77a"
                data-id="9f5f77a"
                data-element_type="column"
              >
                <div className="elementor-widget-wrap elementor-element-populated">
                  <div
                    className="elementor-section elementor-inner-section elementor-element elementor-element-4522805 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                    data-id="4522805"
                    data-element_type="section"
                  >
                    <div className="elementor-container elementor-column-gap-default ">
                      <div
                        className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-367b21f"
                        data-id="367b21f"
                        data-element_type="column"
                      >
                        <div className="elementor-widget-wrap elementor-element-populated">
                          <div
                            className="elementor-element elementor-element-9265126 cms-element elementor-widget elementor-widget-cms_image_single"
                            data-id="9265126"
                            data-element_type="widget"
                            data-widget_type="cms_image_single.default"
                          >
                            <div className="elementor-widget-container animation-top">
                              <div className="cms-image-single cms-animation-bottom-to-top ">
                                <div className="cms-img-box">
                                  <StaticImage
                                    placeholder="none"
                                    width="80"
                                    src="../images/image-home-2.png"
                                    className="attachment-full size-full"
                                    alt=""
                                    loading="lazy"
                                  />{" "}
                                </div>
                              </div>{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-8869f2a"
                        data-id="8869f2a"
                        data-element_type="column"
                      >
                        <div className="elementor-widget-wrap elementor-element-populated">
                          <div
                            className="elementor-element elementor-element-8832270 elementor-widget elementor-widget-cms_heading"
                            data-id="8832270"
                            data-element_type="widget"
                            data-widget_type="cms_heading.default"
                          >
                            <div className="container-center">
                              <div className="cms-heading-wrapper cms-heading-layout1 wow pulse">
                                <h3 className="custom-heading">
                                  <span>
                                    Comprehension Score : {userDetails.score['comprehension']['percentage']} %
                                  </span>
                                </h3>
                                <ExtraPaddingBottom />
                                <ScoreProgressBar
                                  data={[
                                    {
                                      fillPercent: userDetails.score['comprehension']['percentage'],
                                      fillColor:
                                        colorCodes[
                                        "comprehension"
                                        ],
                                      consideringScoreOf:
                                        "comprehension",
                                    },
                                  ]}
                                />
                                <p>
                                  % skills equal to or more
                                  advanced than average peers.
                                </p>
                                <p className="text-left">
                                  Language comprehension refers
                                  to all the things your child
                                  understands. Comprehension
                                  skills include the words your
                                  child can understand,
                                  questions they can answer, and
                                  directions they can follow.
                                </p>
                                <ExtraPaddingBottom />
                                <a
                                  class="learn-more-btn"
                                  href="/reports?skill=comprehension"
                                >
                                  <span class="btn-text">
                                    Learn More{" "}
                                  </span>
                                </a>
                              </div>{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-6be929f"
                        data-id="6be929f"
                        data-element_type="column"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* pronounciation */}
        {
          userDetails.score['pronunciation']
            ? <div className="findings-review-block elementor-section-wrap">
              <section
                className="elementor-section elementor-top-section elementor-element elementor-element-f6893e0 elementor-section-stretched elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                data-id="f6893e0"
                data-element_type="section"
                data-settings='{"stretch_section":"section-stretched"}'
              >
                <div className="elementor-container elementor-column-gap-default ">
                  <div
                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-9f5f77a"
                    data-id="9f5f77a"
                    data-element_type="column"
                  >
                    <div className="elementor-widget-wrap elementor-element-populated">
                      <div
                        className="elementor-section elementor-inner-section elementor-element elementor-element-4522805 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                        data-id="4522805"
                        data-element_type="section"
                      >
                        <div className="elementor-container elementor-column-gap-default ">
                          <div
                            className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-367b21f"
                            data-id="367b21f"
                            data-element_type="column"
                          >
                            <div className="elementor-widget-wrap elementor-element-populated">
                              <div
                                className="elementor-element elementor-element-9265126 cms-element elementor-widget elementor-widget-cms_image_single"
                                data-id="9265126"
                                data-element_type="widget"
                                data-widget_type="cms_image_single.default"
                              ></div>
                            </div>
                          </div>
                          <div
                            className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-8869f2a"
                            data-id="8869f2a"
                            data-element_type="column"
                          >
                            <div className="elementor-widget-wrap elementor-element-populated">
                              <div
                                className="elementor-element elementor-element-8832270 elementor-widget elementor-widget-cms_heading"
                                data-id="8832270"
                                data-element_type="widget"
                                data-widget_type="cms_heading.default"
                              >
                                <div className="container-center">
                                  <div className="cms-heading-wrapper cms-heading-layout1 wow pulse">
                                    <h3 className="custom-heading">
                                      <span>
                                        Pronunciation Score : {userDetails.score['pronunciation']['percentage']}%
                                      </span>
                                    </h3>
                                    <ExtraPaddingBottom />
                                    <ScoreProgressBar
                                      data={[
                                        {
                                          fillPercent: userDetails.score['pronunciation']['percentage'],
                                          fillColor:
                                            colorCodes[
                                            "pronunciation"
                                            ],
                                          consideringScoreOf:
                                            "pronunciation",
                                        },
                                      ]}
                                    />
                                    <p>
                                      % skills equal to or more
                                      advanced than average peers.
                                    </p>
                                    <p className="text-left">
                                      Pronunciation skills refer to
                                      the specific sounds your child
                                      can make. As your child’s
                                      vocabulary grows, their
                                      pronunciation improves and it
                                      becomes easier to understand
                                      what they are saying.
                                    </p>
                                    <ExtraPaddingBottom />
                                    <a
                                      class="learn-more-btn"
                                      href="/reports?skill=pronunciation"
                                    >
                                      <span class="btn-text">
                                        Learn More{" "}
                                      </span>
                                    </a>
                                  </div>{" "}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-6be929f"
                            data-id="6be929f"
                            data-element_type="column"
                          >
                            <div className="elementor-widget-container animation-top">
                              <div className="cms-image-single cms-animation-bottom-to-top ">
                                <div className="cms-img-box">
                                  <StaticImage
                                    placeholder="none"
                                    width="80"
                                    src="../images/image-home-1.png"
                                    className="attachment-full size-full"
                                    alt=""
                                    loading="lazy"
                                  />{" "}
                                </div>
                              </div>{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            : null
        }

        {/* Readiness */}

        <div className="findings-review-block elementor-section-wrap">
          <section
            className="elementor-section elementor-top-section elementor-element elementor-element-f6893e0 elementor-section-stretched elementor-section-full_width elementor-section-height-default elementor-section-height-default"
            data-id="f6893e0"
            data-element_type="section"
            data-settings='{"stretch_section":"section-stretched"}'
          >
            <div className="elementor-container elementor-column-gap-default ">
              <div
                className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-9f5f77a"
                data-id="9f5f77a"
                data-element_type="column"
              >
                <div className="elementor-widget-wrap elementor-element-populated">
                  <div
                    className="elementor-section elementor-inner-section elementor-element elementor-element-4522805 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                    data-id="4522805"
                    data-element_type="section"
                  >
                    <div className="elementor-container elementor-column-gap-default ">
                      <div
                        className="elementor-column elementor-col-80 elementor-inner-column elementor-element elementor-element-367b21f"
                        data-id="367b21f"
                        data-element_type="column"
                      >
                        <div className="elementor-widget-wrap elementor-element-populated">
                          <div
                            className="elementor-element elementor-element-9265126 cms-element elementor-widget elementor-widget-cms_image_single"
                            data-id="9265126"
                            data-element_type="widget"
                            data-widget_type="cms_image_single.default"
                          >
                            <div className="elementor-widget-container animation-top">
                              <div className="cms-image-single cms-animation-bottom-to-top ">
                                <div className="cms-img-box">
                                  <StaticImage
                                    placeholder="none"
                                    width="80"
                                    src="../images/image-home-3.png"
                                    className="attachment-full size-full"
                                    alt=""
                                    loading="lazy"
                                  />{" "}
                                </div>
                              </div>{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-8869f2a"
                        data-id="8869f2a"
                        data-element_type="column"
                      >
                        <div className="elementor-widget-wrap elementor-element-populated">
                          <div
                            className="elementor-element elementor-element-8832270 elementor-widget elementor-widget-cms_heading"
                            data-id="8832270"
                            data-element_type="widget"
                            data-widget_type="cms_heading.default"
                          >
                            <div className="container-center">
                              <div className="cms-heading-wrapper cms-heading-layout1 wow pulse">
                                <h3 className="custom-heading">
                                  <span>
                                    Readiness Score : {userDetails.score['readiness']['percentage']}%
                                  </span>
                                </h3>
                                <ExtraPaddingBottom />
                                <ScoreProgressBar
                                  data={[
                                    {
                                      fillPercent: userDetails.score['readiness']['percentage'],
                                      fillColor:
                                        colorCodes["readiness"],
                                      consideringScoreOf:
                                        "readiness",
                                    },
                                  ]}
                                />
                                <p>
                                  % skills equal to or more
                                  advanced than average peers.
                                </p>
                                <p className="text-left">
                                  Readiness skills focus on how
                                  easily your child pays
                                  attention and goes with the
                                  flow. By supporting your
                                  child’s attention and reducing
                                  their frustration, you can
                                  help your child learn new
                                  communication skills in less
                                  time.
                                </p>
                                <ExtraPaddingBottom />
                                <div className="">
                                  <a
                                    class="learn-more-btn"
                                    href="/reports?skill=readiness"
                                  >
                                    <span class="btn-text">
                                      Learn More{" "}
                                    </span>
                                  </a>
                                </div>
                              </div>{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-6be929f"
                        data-id="6be929f"
                        data-element_type="column"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    )
  }

  function renderSkillsToSupport() {
    return (
      <div className="container-center" style={{ width: '80%', margin: '0px auto' }}>
        <ExtraPaddingBottom />
        <div className="cms-heading-wrapper cms-heading-layout1 wow pulse">
          <h4 className="custom-heading text-center mb-4">
            Skills To Support
          </h4>

          {
            userDetails.remarks.skillsToSupport.map((skill, index) => {
              return (
                <div key={index}
                  style={{
                    display: 'flex', alignItems: 'flex-start', lineHeight: "2", color: "#271344", fontWeight: "bold"
                  }}>
                  <img
                    src={require('../images/bullet-expression.svg')}
                    style={{ marginRight: '16px', marginTop: '6px' }}
                    height='32'
                    width='32' />
                  <p>{skill}</p>
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }

  function renderOnTrackSkills() {
    return (
      <div className="container-center" style={{ width: '80%', margin: '0px auto' }}>
        <div className="cms-heading-wrapper cms-heading-layout1 wow pulse">
          <h4 className="custom-heading text-center mb-4">
            On Track Skills
          </h4>
          {
            userDetails.remarks.onTrackSkills.map((skill, index) => {
              return (
                <div key={index} style={{
                  display: 'flex', alignItems: 'flex-start', lineHeight: "2", color: "#271344", fontWeight: "bold"
                }}>
                  <img
                    src={require('../images/bullet-circle-check.svg')}
                    style={{ marginRight: '16px', marginTop: '6px' }}
                    height='32'
                    width='32' />
                  <p>{skill}</p>
                </div>
              )
            })
          }
        </div>
        <ExtraPaddingBottom />
      </div>
    )
  }

  const renderRemarks = () => {
    return (
      <div style={{ marginBottom: '80px' }}>
        {renderOnTrackSkills()}
        {renderSkillsToSupport()}
      </div>
    )
  }

  const navigateTo = () => {
    // window.location.href = `/pricing`
    // window.location.href = `/child-program`
    // replace /child-program with /toddler-talk-plan-tutorial url
    window.location.href = '/toddler-talk-plan-tutorial'  
  }
  let date = new Date(userDetails.birthday);
  let  birthdayDate = moment.utc(date).format('M/D/YYYY')

  return (
    <Auth>
      <Layout>
        <div id="content" className="site-content">
          <div className="content-inner">
            <div className="">
              <div className="row content-row">
                <div id="primary" className="content-area">
                  <main id="main" className="site-main">
                    <article
                      id="post-45"
                      className="post-45 page type-page status-publish hentry"
                    >
                      <div className="entry-content clearfix">
                        <div
                          data-elementor-type="wp-page"
                          data-elementor-id="45"
                          className="elementor elementor-45"
                          data-elementor-settings="[]"
                        >
                          <div className="elementor-section-wrap">
                            <section
                              className=" elementor-section elementor-top-section elementor-element elementor-element-f6893e0 elementor-section-stretched elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                              data-id="f6893e0"
                              data-element_type="section"
                              data-settings='{"stretch_section":"section-stretched"}'
                            >
                              <div className=" elementor-container elementor-column-gap-default ">
                                <div
                                  className=" elementor-column elementor-col-12 elementor-top-column elementor-element elementor-element-9f5f77a"
                                  data-id="9f5f77a"
                                  data-element_type="column"
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="  elementor-section elementor-inner-section elementor-element elementor-element-4522805 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                                      data-id="4522805"
                                      data-element_type="section"
                                    >
                                      <div className="elementor-container elementor-column-gap-default ">
                                        <div
                                          className="elementor-column elementor-col-10 elementor-inner-column elementor-element elementor-element-367b21f"
                                          data-id="367b21f"
                                          data-element_type="column"
                                        >
                                          <div className="elementor-widget-wrap elementor-element-populated">
                                            <div
                                              className="elementor-element elementor-element-9265126 cms-element elementor-widget elementor-widget-cms_image_single"
                                              data-id="9265126"
                                              data-element_type="widget"
                                              data-widget_type="cms_image_single.default"
                                            >
                                              <div className="elementor-widget-container animation-top">
                                                <div className="cms-image-single cms-animation-left-to-right ">
                                                  <div className="cms-img-box">
                                                    <StaticImage
                                                      placeholder="none"
                                                      width="107"
                                                      src="../images/image-home-1.png"
                                                      className="attachment-full size-full"
                                                      alt=""
                                                      loading="lazy"
                                                    />{" "}
                                                  </div>
                                                </div>{" "}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="elementor-column elementor-col-80 elementor-inner-column elementor-element elementor-element-8869f2a"
                                          data-id="8869f2a"
                                          data-element_type="column"
                                        >
                                          <div className="elementor-widget-wrap elementor-element-populated">
                                            <div
                                              className="px-0 elementor-element elementor-element-8832270 elementor-widget elementor-widget-cms_heading"
                                              data-id="8832270"
                                              data-element_type="widget"
                                              data-widget_type="cms_heading.default"
                                            >
                                              <div className="container-center">
                                                <div class="cms-heading-wrapper">
                                                  <h3
                                                    class="custom-heading primary-color"
                                                    style={{
                                                      fontFamily:
                                                        "Schoolbell,Sans-serif",
                                                      fontSize: "17px",
                                                      fontWeight: 600,
                                                      letterSpacing: "3.5px",
                                                    }}
                                                  >
                                                    <span>Findings Report</span>
                                                  </h3>
                                                </div>
                                                <div className="cms-heading-wrapper cms-heading-layout1 wow pulse">
                                                  <h3 className="custom-heading">
                                                    <span>
                                                      {userDetails.name}'s Communication
                                                      Profile:
                                                    </span>
                                                  </h3>
                                                </div>{" "}
                                              </div>
                                              <ExtraPaddingBottom />
                                              <div className="findings-profile-info">
                                                <div class="profile-info-row width-100">
                                                  <div>
                                                    <p className="findings-info-text">
                                                      Age: (Reported in Months)
                                                    </p>
                                                  </div>
                                                  <p className="profile-info-seperator"></p>
                                                  <div>
                                                    <p className="findings-info-text">
                                                      {userDetails.age} months
                                                    </p>
                                                  </div>
                                                </div>
                                                <div class="profile-info-row width-100">
                                                  <div>
                                                    <p className="findings-info-text">
                                                      Birthday:
                                                    </p>
                                                  </div>
                                                  <p className="profile-info-seperator"></p>
                                                  <div>
                                                    <p className="findings-info-text">
                                                      {userDetails.birthday ? birthdayDate : ''}
                                                    </p>
                                                  </div>
                                                </div>
                                                <div class="profile-info-row width-100">
                                                  <div>
                                                    <p className="findings-info-text">
                                                      Questionnaire Completed On:
                                                    </p>
                                                  </div>
                                                  <p className="profile-info-seperator"></p>
                                                  <div>
                                                    <p className="findings-info-text">
                                                      {userDetails.birthday ? new Date(userDetails.completedOn).toLocaleDateString() : ''}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>

                                              <ExtraPaddingBottom />
                                              <ExtraPaddingBottom />

                                              {
                                                Object.keys(userDetails.remarks).length
                                                  ? null
                                                  : <div className="container-center">
                                                    <div className="cms-heading-wrapper cms-heading-layout1 wow pulse">
                                                      <h3 className="custom-heading">
                                                        <span>
                                                          Overall Communication Score
                                                          : {userDetails.overallPercentage.totalPercentage} %
                                                        </span>
                                                      </h3>
                                                    </div>{" "}
                                                    <ExtraPaddingBottom />
                                                    <ScoreProgressBar
                                                      data={[
                                                        {
                                                          fillPercent: userDetails['overallPercentage']['expression'],
                                                          fillColor:
                                                            colorCodes["expression"],
                                                          consideringScoreOf:
                                                            "expression",
                                                        },
                                                        {
                                                          fillPercent: userDetails['overallPercentage']['comprehension'],
                                                          fillColor:
                                                            colorCodes[
                                                            "comprehension"
                                                            ],
                                                          consideringScoreOf:
                                                            "comprehension",
                                                        },
                                                        {
                                                          fillPercent: userDetails['overallPercentage']['pronunciation'],
                                                          fillColor:
                                                            colorCodes[
                                                            "pronunciation"
                                                            ],
                                                          consideringScoreOf:
                                                            "pronunciation",
                                                        },
                                                        {
                                                          fillPercent: userDetails['overallPercentage']['readiness'],
                                                          fillColor:
                                                            colorCodes["readiness"],
                                                          consideringScoreOf:
                                                            "readiness",
                                                        },
                                                      ]}
                                                    />
                                                    <p className="text-center">
                                                      % skills equal to or more
                                                      advanced than average peers
                                                    </p>
                                                    <ExtraPaddingBottom />
                                                    <ExtraPaddingBottom />
                                                    <Row className='align-items-center'>
                                                      <Col md="6" className="text-center">
                                                        <button
                                                          class="cont-lesson-plan-btn"
                                                          onClick={navigateTo}
                                                        >
                                                          <span className="lesson-plan-btn-text">
                                                            Continue to your lesson
                                                            plan{" "}
                                                          </span>
                                                        </button>
                                                        <p className="mt-2">{descriptionContinueToLessonPlan}</p>
                                                      </Col>

                                                      <Col md="6" className="text-center">
                                                        <button
                                                          class="reset-quiz-btn"
                                                          onClick={restartQuizPopup}
                                                        >
                                                          <span className="lesson-plan-btn-text">
                                                            Retake Assessment
                                                          </span>
                                                        </button>
                                                        <p className="mt-2">{descriptionRetakeAssessment}</p>
                                                      </Col>

                                                    </Row>
                                                  </div>
                                              }

                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="elementor-column elementor-col-10 elementor-inner-column elementor-element elementor-element-6be929f"
                                          data-id="6be929f"
                                          data-element_type="column"
                                        >
                                          <div className="elementor-widget-wrap elementor-element-populated">
                                            <div
                                              className="elementor-element elementor-element-97fbc33 cms-animation-bottom-to-top2 elementor-widget elementor-widget-cms_image_single"
                                              data-id="97fbc33"
                                              data-element_type="widget"
                                              data-widget_type="cms_image_single.default"
                                            >
                                              <div className="elementor-widget-container animation-bottom">
                                                <div className="cms-image-single cms-animation-bottom-to-top ">
                                                  <div className="cms-img-box">
                                                    <StaticImage
                                                      placeholder="none"
                                                      width="120"
                                                      height="120"
                                                      src="../images/image-home-3.png"
                                                      className="attachment-full size-full"
                                                      alt=""
                                                      loading="lazy"
                                                    />{" "}
                                                  </div>
                                                </div>{" "}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                          </div>

                          {
                            Object.keys(userDetails.remarks).length
                              ? renderRemarks()
                              : renderResults()
                          }

                        </div>
                      </div>
                    </article>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          show={replayQuiz ? true : false}
          size="lg"
        >
          <Modal.Header>
            <Modal.Title>Are you sure you want to retake the assessment ?</Modal.Title>
          </Modal.Header>

          <Modal.Footer>
            <Button onClick={() => { resetQuiz() }}>Yes</Button>
            <Button onClick={() => setReplayQuiz(false)}>No</Button>
          </Modal.Footer>
        </Modal>



      </Layout>
    </Auth>
  );
};

export default Findings;